import React from 'react'

const Screen = () => {
  return (
    <>
        <section className='sm_80 screens' id="screen">
            <div className="container px-1 px-sm-5">
                <div className="text-center">
                    <div className="section-title">
                        <h2 className="position-relative d-inline-block">Our Work</h2>
                    </div>
                </div>

                <div className="">
                    <div className='screen_div'>
                            <div className='image tran-5' style={{backgroundImage:"url(img/screen/sc-1.jpg)"}} >
                                <span className='overly'><i className="fas fa-arrow-up"></i></span>
                            </div>
                            <div className='image tran-5' style={{backgroundImage:"url(img/screen/sc-2.jpg)"}} >
                                <span className='overly'><i className="fas fa-arrow-up"></i></span>
                            </div>
                            <div className='image tran-5' style={{backgroundImage:"url(img/screen/sc-3.jpg)"}} >
                                <span className='overly'><i className="fas fa-arrow-up"></i></span>
                            </div>
                            <div className='image tran-5' style={{backgroundImage:"url(img/screen/sc-4.jpg)"}} >
                                <span className='overly'><i className="fas fa-arrow-up"></i></span>
                            </div>
                            <div className='image tran-5' style={{backgroundImage:"url(img/screen/sc-5.jpg)"}} >
                                <span className='overly'><i className="fas fa-arrow-up"></i></span>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Screen