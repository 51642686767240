import React from 'react';

const AboutUs = () => {
    return (
        <>
           <section id="about" className="about_us">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-1 text-center text-lg-start mb-5" >
                            <div className="section-title">
                                <h2 className="position-relative d-inline-block">About Us</h2>
                            </div>
                            <p className="mb-2 text-white">CybxTech is a global It solution company that develops digital products, platforms, and experiences for their clients that engage users and scale on demand.</p>  
                            <p className="mb-2 text-white"><strong>Purpose:</strong> We empower our global partners to achieve great outcomes with technology.</p>
                            <p className="mb-2 text-white"><strong>Mission:</strong> To connect the global IT ecosystem and unlock its potential for all.</p> 
                            <p className="mb-2 text-white"><strong>Vision:</strong> We deliver compelling technology solutions to a dynamic global marketplace.</p>  
                        </div>
                        <div className="col-lg-6 order-lg-0 text-center" >
                            <div className='margins'>
                                <div className="about_img">
                                    <img src="/img/about-us-page-animate.svg" alt="" className="img-fluid"/>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUs;
