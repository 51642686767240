import React from 'react'
import Banner from './Banner'
// import { HashLink as Link } from 'react-router-hash-link';

const NavBar = () => {
  return (
    <>
         <header id='full_name'>
            <div className="header">
                <div className="container">
                    <nav className="navbar navbar-expand-lg main-header">
                        <div className="container d-flex align-items-center justify-content-between">
                        <div className="navbar-brand jm-logo">
                            <a className="logo test-shine" href="#">
                                <span> </span>
                                <img src="img/logo.png" alt="logo" className="img-fluid" />
                            </a>
                        </div>
                        <button className="navbar-toggler p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navbar">
                            <i className="fas fa-bars navbar-toggler-icon"></i>
                           
                        </button>
                        <div className="collapse navbar-collapse align-items-center" id="navbarNav">
                            <ul className="navbar-nav ms-auto mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active"   aria-current="page" href="#">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#services">Services</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#tech">Technology</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#screen">Work</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#about">About Us</a>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </nav>
                </div>
            </div>   
        </header>
        
        <Banner/>
    </>
  )
}

export default NavBar
