import './App.css';
import AnimatedCursor from 'react-animated-cursor';
import NavBar from './componants/NavBar';
import Home from './pages/Home';

function App() {
  return (
    <>
    <NavBar/>
    <Home/>
    <AnimatedCursor innerSize={8}
      outerSize={8}
      color='166, 231, 245' outerAlpha={0.4}
      innerScale={0.7}
      outerScale={2} trailingSpeed={8} outerStyle={{
        padding: "10px",
        backgroundColor: "transparent",
        border: '1px solid rgb(166, 231, 245)'
      }}  clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link'
      ]}/>
    </>
  );
}

export default App;
