import React from 'react';

const Technology = () => {
    
    return (
        <> 
            <section id="tech" className="technology sm_80">
                <div className="container">
                    <div className="text-center">
                        <div className="section-title">
                            <h2 className="position-relative d-inline-block">Technology Stack</h2>
                        </div>
                    </div>
                    <div id="Indicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#Indicators" data-bs-slide-to="0" className="text active" aria-current="true" aria-label="Slide 1">FrontEnd</button>
                            <button type="button" data-bs-target="#Indicators" data-bs-slide-to="1" aria-label="Slide 2" className='text'>BackEnd</button>
                            <button type="button" data-bs-target="#Indicators" data-bs-slide-to="2" aria-label="Slide 3" className='text'>Database</button>
                            <button type="button" data-bs-target="#Indicators" data-bs-slide-to="3" aria-label="Slide 4" className='text'>Mobile</button>
                            <button type="button" data-bs-target="#Indicators" data-bs-slide-to="4" aria-label="Slide 5" className='text'>Cloud</button>
                        </div>
                        <div className="carousel-inner g-0" role="listbox">
                            <div className="carousel-item active">
                                <div className="border-0 mr_b">
                                    <div className="bt-4 d-flex justify-content-center flex-wrap">
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-right">
                                                <img src="/img/icon/react-native.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">React JS</h6>
                                            </div>
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-up">
                                                <img src="/img/icon/vue-js.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">Vue Js</h6>
                                            </div>
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-left">
                                                <img src="/img/icon/angularjs.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">Angular Js</h6>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="border-0 mr_b">
                                    <div className="bt-4 d-flex justify-content-center flex-wrap">
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-left">
                                                <img src="/img/icon/python.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">Python</h6>
                                            </div>
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-up">
                                                <img src="/img/icon/nodejs-svg.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">Node Js</h6>
                                            </div>
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-up">
                                                <img src="/img/icon/PHP.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">PHP</h6>
                                            </div>
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-right">
                                                <img src="/img/icon/dotnet-icon.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">.Net</h6>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="border-0 mr_b">
                                    <div className="bt-4 d-flex justify-content-center flex-wrap">
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-left">
                                                <img src="/img/icon/my-SQL.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">MySQL</h6>
                                            </div>
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-up">
                                                <img src="/img/icon/sql-60.png" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title_o">SQL</h6>
                                            </div>
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-up">
                                                <img src="/img/icon/SQL-server.svg" className="ct-image tab-image_o img-fluid" alt=""/>
                                                <h6 className="stack_title_o">Microsoft SQLServer</h6>
                                            </div>
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-up">
                                                <img src="/img/icon/postgre.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">PostgreSQL</h6>
                                            </div>
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-right">
                                                <img src="/img/icon/mongodb.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">MongoDB</h6>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="border-0 mr_b">
                                    <div className="bt-4 d-flex justify-content-center flex-wrap">
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-left">
                                                <img src="/img/icon/android.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">Android</h6>
                                            </div>
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-up">
                                                <img src="/img/icon/apple-ios-svg.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">iOS</h6>
                                            </div>
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-up">
                                                <img src="/img/icon/fulter.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">Flutter</h6>
                                            </div>
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-right">
                                                <img src="/img/icon/react-native.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">ReactNative</h6>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="border-0 mr_b">
                                    <div className="bt-4 d-flex justify-content-center flex-wrap">
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-left">
                                                <img src="/img/icon/amazon-web-services-logo-svg.svg" className="ct-image tab-image img-fluid" alt=""/>
                                                <h6 className="stack_title">AWS</h6>
                                            </div>
                                            <div className="stack_item tab_section aos-init aos-animate" data-aos-duration="1800" data-aos="fade-right">
                                                <img src="/img/icon/icons8-azure-80.png" width="50" height="50" className="ct-image tab-image_o img-fluid" alt=""/>
                                                <h6 className="stack_title_o">Microsoft Azure</h6>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
        </>
    );
}

export default Technology;
