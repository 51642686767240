import React from 'react';

const Banner = () => {
    return (
        <>
           <section className='banner' id='home'>
                <div className="container">
                        <div className="row justify-content-center align-items-center h-100 glss">
                            <div className="col-lg-8 col-md-10 col-12 text-center  ">
                                <div className="banner_contant " >
                                    <h4>Global IT Solution</h4>
                                    <h1 className='' data-aos-duration="1500" data-aos="fade-left">CYBX TECHNOLABS</h1>
                                    <p><span className='f_text'>Cyb</span>er E<span className='f_text'>x</span>perts at work. A team of expert engineers with foremost and dynamic technical skills largely focused on creating and building software for clients/businesses to meet their  business needs.</p>
                                    <a href="#footer" className="btn btn-primary">Explore Now</a>
                                </div>
                            </div>
                        </div>    
                </div>
            </section>
        </>
    );
}

export default Banner;
