import React from 'react'
import AboutUs from '../componants/AboutUs'
import Footer from '../componants/Footer'
import Services from '../componants/Services'
import Technology from '../componants/Technology'
import Screen from '../componants/Screen'
const Home = () => {
  return (
    <>
    <Services/>
    <Technology/>
    <Screen/>
    <AboutUs/>
    <Footer/>
    </>
  )
}

export default Home