import React from 'react'

const Services = () => {
  return (
    <>
        <section id='services' className='sm_80 service_area'>
            <div className="container">
                <div className="text-center">
                    <div className="section-title">
                        <h2 className="position-relative d-inline-block">Services</h2>
                    </div>
                </div>
                <div className="row gy-5">
                    <div className="col-md-6 col-lg-6 col-xl-4 align-items-center aos-init aos-animate" data-aos-duration="1500" data-aos="fade-up">
                        <div className="single-box">
                            <div className="single_icon">
                                <img src="img/icon-32.png" alt="" className='icon img-fluid' />
                            </div>
                            <h3>Mobile App Development </h3>
                            <p><strong>Android, iOS  App Services</strong> <br />
                            Our service includes  enterprise software, 3D animation, games, and virtual reality applications.
                            </p>
                            {/* <a href="#about" title="Read More" className="link-btn"><i className="fas fa-arrow-right"></i></a> */}
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-4 align-items-center aos-init aos-animate" data-aos-duration="1500" data-aos="fade-up">
                        <div className="single-box">
                            <div className="single_icon">
                                <img src="img/icon-42.png" alt="" className='icon img-fluid' />
                            </div>
                            <h3>Software Development</h3>
                            <p>To build & design a custom software for small businesses to Enterprise-Level Corporations.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-4 align-items-center aos-init aos-animate" data-aos-duration="1500" data-aos="fade-up">
                        <div className="single-box">
                            <div className="single_icon">
                                <img src="img/icon-37.png" alt="" className='icon img-fluid' />
                            </div>
                            <h3>Web Development</h3>
                            <p>We building top notch custom Web Applications, Websites as per business needs, market trends and especially within customer budget.</p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-4 align-items-center aos-init aos-animate" data-aos-duration="1500" data-aos="fade-up">
                        <div className="single-box">
                            <div className="single_icon">
                                <img src="img/icon-39.png" alt="" className='icon img-fluid' />
                            </div>
                            <h3>Web Designing</h3>
                            <p>Provide unique and compelling UX/UI design for Web, Software and Mobile Applications using up-to-date tools with 2D and 3D design effects.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-4 align-items-center aos-init aos-animate" data-aos-duration="1500" data-aos="fade-up">
                        <div className="single-box">
                            <div className="single_icon">
                                <img src="img/icon-38.png" alt="" className='icon img-fluid' />
                            </div>
                            <h3>Dedicated Developers</h3>
                            <p>We provide skilled, experienced dedicated developers and software engineers.
                            </p>
                            <ul className="list">
                                <li><strong>Salary Budget:</strong> Fixed, Hourly, Monthly</li>
                                <li><strong>Contractual basis:</strong> (Long / Short Term)</li>
                                </ul>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-xl-4 align-items-center aos-init aos-animate" data-aos-duration="1500" data-aos="fade-up">
                        <div className="single-box">
                            <div className="single_icon">
                                <img src="img/icon-34.png" alt="" className='icon img-fluid' />
                            </div>
                            <h3>QA and Testing</h3>
                            <p>Our QA team works with diversity to monitor every phase of the development process.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Services